<template>
  <a-modal
    v-model="show"
    :centered="true"
    :keyboard="false"
    :footer="null"
    :width="1000"
    @cancel="handleCancleClick"
    title="用户信息">
    <a-layout class="user-info">
      <a-layout-sider class="user-info-side">
        <a-avatar shape="square" style="margin-bottom: 20px" :size="115" icon="user" :src="`static/avatar/${userInfoData.avatar}`"/><br/>
        <label class="empno-label">工号</label>
        <span class="empno">{{userInfoData.empno}}</span>
      </a-layout-sider>
      <a-layout-content class="user-content-one">
        <p><my-icon type="userName"/>姓名：{{userInfoData.username}}</p>
        <p><my-icon type="englishName"/>英文名：{{userInfoData.usernameEn ? userInfoData.usernameEn : '暂无' }}</p>
        <p :title="userInfoData.roleName"><my-icon type="character"/>角色：{{userInfoData.roleName? userInfoData.roleName: '暂无角色'}}</p>
        <p><my-icon type="sex"/>性别：{{sex}}</p>
        <p><a-icon type="mail"/>邮箱：{{userInfoData.email ? userInfoData.email : '暂无'}}</p>
        <p><my-icon type="address"/>住址：{{userInfoData.address ? userInfoData.address : '暂无'}}</p>
        <p><my-icon type="personalTitle"/>个人称号：{{userInfoData.personalTitleNote ? userInfoData.personalTitleNote : '暂无'}}</p>
      </a-layout-content>
      <a-layout-content class="user-content-two">
        <p><my-icon type="department"/>部门：{{userInfoData.deptName ? userInfoData.deptName : '暂无'}}</p>
        <p><my-icon type="job"/>职位：{{userInfoData.deptPositionName ? userInfoData.deptPositionName : '暂无'}}</p>
        <p><my-icon type="hisSupervisor"/>他的主管：{{userInfoData.lineManager ? userInfoData.lineManager : '暂无'}}</p>
        <p><my-icon type="personnelCategory"/>人员类别：{{getValue(userInfoData.userType, userTypes)}}</p>
        <p><my-icon type="emergencyContact"/>紧急联系人：{{getValue(userInfoData.emergency, emergencys)}}</p>
        <p><my-icon type="emergencyPhone"/>紧急联系人电话：{{userInfoData.emergencyPhone ? userInfoData.emergencyPhone : '暂无'}}</p>
      </a-layout-content>
      <a-layout-content class="user-content-three">
        <p><my-icon type="privatePhone"/>私人电话：{{userInfoData.mobile ? userInfoData.mobile : '暂无'}}</p>
        <p><my-icon type="companyPhone"/>公司号码：{{userInfoData.officePhone ? userInfoData.officePhone : '暂无'}}</p>
        <p><my-icon type="phone"/>固定电话：{{userInfoData.fixPhone ? userInfoData.fixPhone : '暂无'}}</p>
        <p>
          <a-icon type="smile" v-if="userInfoData.status === '1'"/>
          <a-icon type="frown" v-else/>状态：
          <template v-if="userInfoData.status === '0'">
            <a-tag color="red">锁定</a-tag>
          </template>
          <template v-else-if="userInfoData.status === '1'">
            <a-tag color="cyan">有效</a-tag>
          </template>
          <template v-else>
            {{userInfoData.status}}
          </template>
        </p>
        <p><a-icon type="clock-circle"/>创建时间：{{datetime(userInfoData.createTime)}}</p>
        <p><a-icon type="login" />最近登录：{{datetime(userInfoData.lastLoginTime)}}</p>
      </a-layout-content>
    </a-layout>
    <a-layout class="user-info" v-if="userInfoData.description">
      <a-layout-sider class="user-info-side">
      </a-layout-sider>
      <a-layout-content class="user-content-three">
        <span><my-icon type="jobDesc"/>用户描述：</span><br/>
        <span class="info" :title="userInfoData.description">{{userInfoData.description}}</span>
      </a-layout-content>
    </a-layout>
  </a-modal>
</template>
<script>
export default {
  name: 'UserInfo',
  props: {
    userInfoVisiable: {
      require: true,
      default: false
    },
    userInfoData: {
      require: true
    },
    userTypes: {
      type: Array,
      required: true,
      default: () => [],
    },
    emergencys: {
      type: Array,
      required: true,
      default: () => [],
    },
    ebs: {
      type: Array,
      required: true,
      default: () => [],
    },
    ssexs: {
      type: Array,
      required: true,
      default: () => [],
    },
    statuss: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    show: {
      get: function () {
        return this.userInfoVisiable
      },
      set: function () {
      }
    },
    sex () {
      for (let index in this.ssexs) {
        if (this.userInfoData.ssex === this.ssexs[index].keyy) {
          return this.ssexs[index].valuee
        } else {
          continue
        }
      }
      return this.userInfoData.ssex
    }
  },
  methods: {
    handleCancleClick () {
      this.$emit('close')
    },
    getValue(key, fromData) {
      let d = fromData.find(item=>item.keyy == key)
      return d ? d.valuee : '暂无'
    }
  }
}
</script>
<style lang="less" scoped>
@import "UserInfo";
</style>
