<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item label="用户名">
              <a-input v-model="queryParams.username" />
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item label="部门">
              <dept-input-tree
                @change="handleDeptChange"
                :topDeptIds="topDeptIds"
                ref="deptTree"
              >
              </dept-input-tree>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item
              label="状态"
            >
              <a-select
                show-search
                placeholder="选择状态"
                v-model="queryParams.status"
              >
                <a-select-option
                  v-for="t in status"
                  :key="t.keyy"
                  :value="t.keyy"
                  >{{ t.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 12 }"
            :xl="{ span: 8 }"
            :xxl="{ span: 8 }"
            :span="4"
          >
            <a-form-item label="创建时间">
              <range-date
                @change="handleDateChange"
                ref="createTime"
              ></range-date>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <div class="operator">
        <a-button
          type="primary"
          ghost
          @click="add"
          v-hasPermission="['user:add']"
          >新增</a-button
        >
        <a-button @click="batchDelete" type="danger" v-hasPermission="['user:delete']"
          >离职</a-button
        >
        <a-button @click="resetPassword" v-hasPermission="['user:reset']"
          >密码重置</a-button
        >
        <a-button
          style="margin-left: 50px"
          @click="downloadTemplate"
          v-hasPermission="['user:import']"
          >模板下载</a-button
        >
        <a-button
          style="margin-left: 50px"
          @click="exportExcel"
          v-hasPermission="['user:export']"
          >导出Excel</a-button
        >
        <a-upload
          style="margin-left: 50px"
          v-hasPermission="['user:import']"
          :fileList="fileList"
          :remove="handleRemove"
          :disabled="fileList.length === 1"
          :beforeUpload="beforeUpload"
        >
          <a-button> <a-icon type="upload" /> 选择.xlsx文件 </a-button>
          <a-button
            class="button-area"
            @click="handleUpload"
            :disabled="fileList.length === 0"
            :loading="uploading"
          >
            {{ uploading ? "导入中" : "导入Excel" }}
          </a-button>
        </a-upload>
      </div>
      <!-- 表格区域 -->
      <a-table
        bordered
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.userId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        :rowSelection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :scroll="{ x: 900 }"
        @change="handleTableChange"
      >
        <template slot="fixPhone" slot-scope="text,record">
          <editable-cell :text="text" @change="onCellChange(record.userId, 'fixPhone', $event)" />
        </template>
        <template slot="officePhone" slot-scope="text,record">
          <editable-cell :text="text" @change="onCellChange(record.userId, 'officePhone', $event)" />
        </template>
        <template slot="email" slot-scope="text,record">
          <editable-cell :text="text" @change="onCellChange(record.userId, 'email', $event)" />
        </template>
        <template slot="roleName" slot-scope="text, record">
          <template v-if="record.roleName">
            <a-popover
              placement="topLeft"
              v-for="(rolename, index) of record.roleName.split(',')"
              :key="index"
            >
              <template slot="content">
                <span>{{ getRoleById(rolename).remark }}</span>
              </template>
              <a-tag color="blue">
                {{ rolename }}
              </a-tag>
            </a-popover>
          </template>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-icon
            v-hasPermission="['user:update']"
            type="edit"
            theme="twoTone"
            twoToneColor="#4a9ff5"
            @click="edit(record)"
            title="修改用户"
          ></a-icon>
          &nbsp;
          <a-icon
            v-hasPermission="['user:view']"
            type="eye"
            theme="twoTone"
            twoToneColor="#42b983"
            @click="view(record)"
            title="查看"
          ></a-icon>
          <a-badge
            v-hasNoPermission="['user:update', 'user:view']"
            status="warning"
            text="无权限"
          ></a-badge>
        </template>
      </a-table>
      <import-result
        @close="handleClose"
        :importData="importData"
        :errors="errors"
        :times="times"
        :importResultVisible="importResultVisible"
      >
      </import-result>
    </div>
    <!-- 用户信息查看 -->
    <user-info
      :userInfoData="userInfo.data"
      :userInfoVisiable="userInfo.visiable"
      :userTypes="userTypes"
      :emergencys="emergencys"
      :ebs="ebs"
      :ssexs="ssexs"
      :statuss="status"
      @close="handleUserInfoClose"
    >
    </user-info>
    <!-- 新增用户 -->
    <!-- 修改用户 -->
    <user-edit
      ref="userEditView"
      @close="() => {}"
      @success="handleUserEditSuccess"
      :userTypes="userTypes"
      :emergencys="emergencys"
      :ebs="ebs"
      :ssexs="ssexs"
      :statuss="status"
    >
    </user-edit>
  </a-card>
</template>

<script>
import UserInfo from "./UserInfo";
import DeptInputTree from "../dept/DeptInputTree";
import RangeDate from "@/components/datetime/RangeDate";
import UserEdit from "./UserEdit";
import { mapState, mapMutations } from "vuex";
import ImportResult from "./ImportResult";
import { getDeptRootId } from "./../../../utils/tools";

const NONESTR = "暂无";

const EditableCell = {
  template: `
      <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
          <a-input :value="value" @change="handleChange" @pressEnter="check" />
          <a-icon type="check" class="editable-cell-icon-check" @click="check" title="确定更新" />
          <a-icon class="editable-cell-icon-close" type="close" @click="close" title="取消更新" />
        </div>
        <div v-else class="editable-cell-text-wrapper">
          {{ value || ' ' }}
          <a-icon type="edit" class="editable-cell-icon" @click="edit" />
        </div>
      </div>
    `,
  props: {
    text: String,
  },
  data() {
    return {
      value: this.text,
      editable: false,
    };
  },
  methods: {
    handleChange(e) {
      const value = e.target.value;
      this.value = value;
    },
    close() {
      this.editable = false;
    },
    check() {
      this.editable = false;
      this.$emit('change', this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};

export default {
  name: "User",
  components: { UserInfo, UserEdit, DeptInputTree, RangeDate, ImportResult , EditableCell},
  data() {
    return {
      advanced: false,
      userInfo: {
        visiable: false,
        data: {},
      },
      userAdd: {
        visiable: false,
      },
      queryParams: {
        status: '1',
        includeFollow: true,
      },
      filteredInfo: null,
      sortedInfo: null,
      paginationInfo: null,
      dataSource: [],
      selectedRowKeys: [],
      roleData: [],
      loading: false,
      pagination: {
        pageSizeOptions: ["10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
      },

      fileList: [],
      importData: [],
      times: "",
      errors: [],
      uploading: false,
      importResultVisible: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.account.user,
      ebs: (state) => state.dict.dicts.t_user_eb,
      emergencys: (state) => state.dict.dicts.t_user_emergency,
      userTypes: (state) => state.dict.dicts.t_user_user_type,
      ssexs: (state) => state.dict.dicts.t_user_ssex,
      status: (state) => state.dict.dicts.t_user_status,
    }),
    topDeptIds() {
      let type = this.$route.path.split("/")[2];
      return getDeptRootId(type);
    },
    columns() {
      let { sortedInfo, filteredInfo } = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      let ebFilters = [];
      let emergencyFilters = [];
      let userTypeFilters = [];
      let sexFilters = [];
      let stateFilters = [];

      for (let index in this.ebs) {
        let obj = { text: this.ebs[index].valuee, value: this.ebs[index].keyy };
        ebFilters.push(obj);
      }
      for (let index in this.emergencys) {
        let obj = {
          text: this.emergencys[index].valuee,
          value: this.emergencys[index].keyy,
        };
        emergencyFilters.push(obj);
      }
      for (let index in this.userTypes) {
        let obj = {
          text: this.userTypes[index].valuee,
          value: this.userTypes[index].keyy,
        };
        userTypeFilters.push(obj);
      }
      for (let index in this.ssexs) {
        let obj = {
          text: this.ssexs[index].valuee,
          value: this.ssexs[index].keyy,
        };
        sexFilters.push(obj);
      }
      for (let index in this.status) {
        let obj = {
          text: this.status[index].valuee,
          value: this.status[index].keyy,
        };
        stateFilters.push(obj);
      }
      return [
        {
          title: "人员类别",
          dataIndex: "userType",
          customRender: (text, row, index) => {
            if (text) {
              for (let index in this.userTypes) {
                if (text === this.userTypes[index].keyy) {
                  return this.userTypes[index].valuee;
                } else {
                  continue;
                }
              }
              return text;
            }
            return NONESTR;
          },
          filters: userTypeFilters,
          filterMultiple: false,
          filteredValue: filteredInfo.userType || null,
          onFilter: (value, record) => {
            return record.userType == value;
          },
          width: 120,
        },
        {
          title: "工号",
          dataIndex: "empno",
          customRender: (text) => {
            return text || NONESTR;
          },
          sorter: true,
          sortOrder: sortedInfo.columnKey === "empno" && sortedInfo.order,
          width: 120,
        },
        {
          title: "姓名",
          dataIndex: "username",
          customRender: (text) => {
            return text || NONESTR;
          },
          sorter: true,
          sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,
          width: 120,
        },
        {
          title: "英文名",
          dataIndex: "usernameEn",
          customRender: (text) => {
            return text || NONESTR;
          },
          sorter: true,
          sortOrder: sortedInfo.columnKey === "usernameEn" && sortedInfo.order,
          width: 120,
        },
        {
          title: "性别",
          dataIndex: "ssex",
          customRender: (text, row, index) => {
            for (let index in this.ssexs) {
              if (text === this.ssexs[index].keyy) {
                return this.ssexs[index].valuee;
              } else {
                continue;
              }
            }
            return text;
          },
          filters: sexFilters,
          filterMultiple: false,
          filteredValue: filteredInfo.ssex || null,
          onFilter: (value, record) => record.ssex.includes(value),
          width: 75,
        },
        {
          title: "状态",
          dataIndex: "status",
          customRender: (text, row, index) => {
            for (let _index in this.status) {
              let key = this.status[_index].keyy;
              if (text === key) {
                let val = this.status[_index].valuee;
                let color = this.status[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          filters: stateFilters,
          filterMultiple: false,
          filteredValue: filteredInfo.status || null,
          onFilter: (value, record) => record.status.includes(value),
          width: 75,
        },
        {
          title: "座机号",
          dataIndex: "fixPhone",
          scopedSlots: { customRender: "fixPhone" },
          width: 140,
        },
        {
          title: "公司号",
          dataIndex: "officePhone",
          scopedSlots: { customRender: "officePhone" },
          width: 180,
        },
        {
          title: "邮箱",
          dataIndex: "email",
          scopedSlots: { customRender: "email" },
          width: 300,
        },
        {
          title: "学历",
          dataIndex: "eb",
          customRender: (text, row, index) => {
            if (text) {
              for (let index in this.ebs) {
                if (text === this.ebs[index].keyy) {
                  return this.ebs[index].valuee;
                } else {
                  continue;
                }
              }
              return text;
            }
            return NONESTR;
          },
          filters: ebFilters,
          filterMultiple: false,
          filteredValue: filteredInfo.eb || null,
          onFilter: (value, record) => record.eb == value,
          width: 160,
        },
        {
          title: "私人号",
          dataIndex: "mobile",
          customRender: (text) => {
            return text || NONESTR;
          },
          width: 150,
        },
        {
          title: "部门",
          dataIndex: "deptName",
          customRender: (text) => {
            return text || NONESTR;
          },
          width: 150,
        },
        {
          title: "职位",
          dataIndex: "deptPositionName",
          customRender: (text) => {
            return text || NONESTR;
          },
          width: 150,
        },
        {
          title: "角色",
          dataIndex: "roleName",
          scopedSlots: { customRender: "roleName" },
          width: 250,
        },
        {
          title: "上属主管",
          dataIndex: "lineManager",
          customRender: (text) => {
            return text || NONESTR;
          },
          scopedSlots: { customRender: "lineManager" },
          width: 150,
        },
        {
          title: "紧急联系人",
          dataIndex: "emergency",
          customRender: (text, row, index) => {
            if (text) {
              for (let index in this.emergencys) {
                if (text === this.emergencys[index].keyy) {
                  return this.emergencys[index].valuee;
                } else {
                  continue;
                }
              }
              return text;
            }
            return NONESTR;
          },
          filters: emergencyFilters,
          filterMultiple: false,
          filteredValue: filteredInfo.emergency || null,
          onFilter: (value, record) => record.emergency == value,
          width: 160,
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          sorter: true,
          sortOrder: sortedInfo.columnKey === "createTime" && sortedInfo.order,
          customRender: (text) => {
            return this.datetime(text);
          },
          width: 160,
        },
        {
          title: "操作",
          dataIndex: "operation",
          fixed: "right",
          scopedSlots: { customRender: "operation" },
          width: 70,
        },
      ];
    },
    userProfileUpdate: (state) => state.$store.state.account.user,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    onCellChange(userId, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find(item => item.userId === userId);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
      this.$put('user', {
              ...target
            }).then((r) => {
              this.$message.success("更新成功");
            }).catch(() => {
            });
    },
    downloadTemplate() {
      window.open("/download/tmpl/用户_导入模板.xlsx");
      // this.$download('user/template', {}, 'User_导入模板.xlsx')
    },
    handleClose() {
      this.importResultVisible = false;
    },
    handleRemove(file) {
      if (this.uploading) {
        this.$message.warning("文件导入中，请勿删除");
        return;
      }
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file];
      return false;
    },
    handleUpload() {
      const { fileList } = this;
      const formData = new FormData();
      formData.append("file", fileList[0]);
      this.uploading = true;
      this.$upload("user/import", formData)
        .then((r) => {
          let data = r.data.data;
          if (data.data.length) {
            this.fetch();
          }
          this.importData = data.data == undefined ? [] : data.data;
          this.errors = data.error == undefined ? [] : data.error;
          this.times = data.time / 1000;
          this.uploading = false;
          this.fileList = [];
          this.importResultVisible = true;
        })
        .catch((r) => {
          console.error(r);
          this.uploading = false;
          this.fileList = [];
        });
    },

    getRoleById(roleName) {
      let role = this.roleData.find((item) => item.roleName == roleName) || {};
      return role;
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
      if (!this.advanced) {
        this.queryParams.createTimeFrom = "";
        this.queryParams.createTimeTo = "";
      }
    },
    view(record) {
      this.userInfo.data = record;
      // 当前用户的头像优先取缓存里的
      if (
        this.userInfo.data.username === this.currentUser.username &&
        this.userInfo.data.avatar !== this.currentUser.avatar
      ) {
        this.userInfo.data.avatar = this.currentUser.avatar;
      }
      this.userInfo.visiable = true;
    },
    add() {
      this.$refs.userEditView.setForm(false);
    },
    handleUserAddSuccess() {
      this.search();
    },
    edit(record) {
      this.$refs.userEditView.setForm(true, record);
    },
    handleUserEditSuccess() {
      this.search();
    },
    handleUserInfoClose() {
      this.userInfo.visiable = false;
    },
    handleDeptChange(value) {
      this.queryParams.rootDeptIds = value;
    },
    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0];
        this.queryParams.createTimeTo = value[1];
      }
    },
    batchDelete() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要离职的员工");
        return;
      }
      let that = this;
      this.$confirm({
        title: "确定这些员工离职吗?",
        content: "当您点击确定按钮后，这些员工将无法登录系统",
        centered: true,
        onOk() {
          let userIds = that.selectedRowKeys.join(",");
          // let selectedRowKeysStr = ',' + that.selectedRowKeys.join(',') + ','
          // userIds.push(that.dataSource.filter(f => { return selectedRowKeysStr.indexOf(',' + f.userId + ',') > -1 }).map(m => { return m.userId }))
          that.$put("user/" + userIds).then(() => {
            that.$message.success("删除成功");
            that.selectedRowKeys = [];
            that.search();
          });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    resetPassword() {
      if (!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要重置密码的用户");
        return;
      }
      let that = this;
      this.$confirm({
        title: "确定重置选中用户密码?",
        content: "当您点击确定按钮后，这些用户的密码将会重置为123456",
        centered: true,
        onOk() {
          let usernames = [];
          let selectedRowKeysStr = "," + that.selectedRowKeys.join(",") + ",";
          usernames.push(
            that.dataSource
              .filter((f) => {
                return selectedRowKeysStr.indexOf("," + f.userId + ",") > -1;
              })
              .map((m) => {
                return m.username;
              })
          );
          that
            .$put("user/password/reset", {
              usernames: usernames.join(","),
            })
            .then(() => {
              that.$message.success("重置用户密码成功");
              that.selectedRowKeys = [];
            });
        },
        onCancel() {
          that.selectedRowKeys = [];
        },
      });
    },
    exportExcel() {
      let { sortedInfo, filteredInfo } = this;
      let sortField, sortOrder;
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field;
        sortOrder = sortedInfo.order;
      }
      let params = { ...this.queryParams };
      if (!params.rootDeptIds) {
        params.rootDeptIds = this.topDeptIds;
      }

      this.$export("user/excel", {
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.params,
        ...filteredInfo,
      });
    },
    search() {
      let { sortedInfo, filteredInfo } = this;
      let sortField, sortOrder;
      // 获取当前列的排序和列的过滤规则
      if (sortedInfo) {
        sortField = sortedInfo.field;
        sortOrder = sortedInfo.order;
      }
      this.selectData({
        sortField: sortField,
        sortOrder: sortOrder,
        ...this.queryParams,
        ...filteredInfo,
      });
    },
    reset() {
      // 取消选中
      this.selectedRowKeys = [];
      // 重置分页
      this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      if (this.paginationInfo) {
        this.paginationInfo.current = this.pagination.defaultCurrent;
        this.paginationInfo.pageSize = this.pagination.defaultPageSize;
      }
      // 重置列过滤器规则
      this.filteredInfo = null;
      // 重置列排序规则
      this.sortedInfo = null;
      // 重置查询参数
      this.queryParams = { includeFollow: this.queryParams.includeFollow };
      // 清空部门树选择
      this.$refs.deptTree.reset();
      // 清空时间选择
      if (this.advanced) {
        this.$refs.createTime.reset();
      }
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      // 将这三个参数赋值给Vue data，用于后续使用
      this.paginationInfo = pagination;
      this.filteredInfo = filters;
      this.sortedInfo = sorter;

      this.userInfo.visiable = false;
      this.fetch({
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...this.queryParams,
        ...filters,
      });
    },
    fetch(
      params = {
        includeFollow: this.queryParams.includeFollow,
      }
    ) {
      console.log(params)
      if (!params.rootDeptIds) {
        params.rootDeptIds = this.topDeptIds;
      }
      params.status = this.queryParams.status

      // 显示loading
      this.loading = true;
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current;
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize;
        params.pageSize = this.paginationInfo.pageSize;
        params.pageNum = this.paginationInfo.current;
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize;
        params.pageNum = this.pagination.defaultCurrent;
      }
      this.$get("user", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        // 数据加载完毕，关闭loading
        this.loading = false;
        if (data.rows == undefined) {
          this.dataSource = []
          return
        }
        this.dataSource = data.rows.map((item)=>{
          item.roleId = item.roleId == undefined ? null : item.roleId
          item.personalTitleKey = item.personalTitleKey == undefined ? null : item.personalTitleKey
          return item
        });
        this.pagination = pagination;
      });

      this.$get("role/list").then((r) => {
        this.roleData = r.data.data;
      });

      // this.$put("applet/user", {"userId":46,"emergencyContact":"小美","emergency":"2","emergencyPhone":"15623543354"}).then((r) => {
      //   console.log(">>>>+++", r)
      // }).catch((err)=>{
      //   console.log(">>>>---", err)
      // });
    },
    selectData(params = {}) {
      this.loading = true;
      // 如果分页信息为空，则设置为默认值
      // this.$refs.TableInfo.pagination.current = this.pagination.defaultCurrent;
      this.$refs.TableInfo.pagination.pageSize = this.pagination.defaultPageSize;
      if (!params.rootDeptIds) {
        params.rootDeptIds = this.topDeptIds;
      }
      params.pageSize = this.pagination.defaultPageSize;
      params.pageNum = this.pagination.defaultCurrent;
      this.$get("user", {
        ...params,
      }).then((r) => {
        let data = r.data;
        const pagination = { ...this.pagination };
        pagination.total = data.total;
        this.loading = false;
        this.dataSource = data.rows;
        this.pagination = pagination;
      });
    },
  },
  watch: {
    userProfileUpdate: {
      handler(newValue, oldValue) {
        let permission = this.$store.state.account.permissions.filter(
          (permission) => permission === "user:view"
        );
        if (typeof permission !== "undefined" && permission !== null) {
          if (
            newValue.email !== oldValue.email ||
            newValue.mobile !== oldValue.mobile ||
            newValue.description !== oldValue.description ||
            newValue.ssex !== oldValue.ssex ||
            newValue.deptId !== oldValue.deptId
          ) {
            this.fetch();
          }
        }
      },
      deep: true,
    },
    topDeptIds() {
      this.$refs.deptTree.setRootDeptIds(this.topDeptIds);
    },
  },
};
</script>
<style lang="less">
@import "../../../utils/Common.less";
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-close,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 50px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-close:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
